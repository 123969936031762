@use "variables" as *;

/* stylelint-disable declaration-no-important */
.app-icon__icon::after {
  background-image: image-url("adapters/svg/unknown.svg", $inline: true);
}

@each $name, $adapter in adapters() {
  .appicon-#{$name}::after {
    background-image: image-url("adapters/svg/#{$name}.svg", $inline: true) !important;
  }
}

.appicon-any::after {
  /* stylelint-disable-next-line color-no-hex -- This color should not change with the theme */
  background-color: #fff;
  background-image: image-url("adapters/svg/any.svg", $inline: true) !important;
  background-size: contain;
}

.appicon-foreach {
  background-color: $repeat-icon-background-color;
  border-color: $repeat-icon-border-color;

  &::after {
    background-image: image-url("adapters/svg/foreach.svg", $inline: true) !important;
  }
}

.appicon-repeat {
  background-color: $repeat-icon-background-color;
  border-color: $repeat-icon-border-color;

  &::after {
    background-image: image-url("adapters/svg/repeat.svg", $inline: true) !important;
  }
}

.appicon-catch {
  background-color: $catch-icon-color;
  border-color: $catch-icon-color;

  &::after {
    background-image: image-url("adapters/svg/catch.svg", $inline: true) !important;
  }
}

.appicon-workato {
  background-color: $mktg-black;
  border-color: $mktg-black;

  &::after {
    background-image: image-url("adapters/svg/workato.svg", $inline: true) !important;
  }
}

.appicon-aws_secrets_manager {
  background: $aws-secrets-manager-background;

  &::after {
    background-image: image-url("adapters/svg/aws_secrets_manager.svg", $inline: true) !important;
  }
}

.appicon-workato_api_proxy {
  &_forward_request {
    &::after {
      background-image: image-url("shared/icons/recipe/step/api-proxy-request.svg", $inline: true) !important;
    }
  }
}

.appicon-workato_apim_request {
  background: $utility-bg-teal;

  &::after {
    background-image: image-url("adapters/svg/workato_apim_request.svg", $inline: true) !important;
  }
}
/* stylelint-enable */
